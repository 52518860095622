@import 'style/vars';

.base {
  min-width: 700px;
  max-width: 1500px;
  background-color: white;
  color: $primary-color-dark;
  border-radius: 5px;
  margin: 10px;
  padding: 30px 40px;
  padding: 30px 60px;
  margin-top: 10px;

  .error {
    color: $font-color-error;
  }

  button {
    font-size: 12px;
  }

  .titleContainer {
    display: flex;
    justify-content: space-between;
  }

  .reportContainer {
    margin-top: 20px;
    .pdfBtn {
      display: flex;
      margin-bottom: 10px;
    }
    .searchInfo {
      font-weight: bold;
    }
  }
}
