@import 'style/vars';
.base {
  table {
    background-color: #08ad5c33;
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;

    thead tr th {
      color: white;
      // background-color: $dark-blue;
      background-color: #08ad5c;
    }
    th,
    td {
      // border: 1px solid #ddd;
      border: 1px solid #fff;
      padding: 6px 8px;
      text-align: left;
    }

    th {
      background-color: #f2f2f2;
    }

    tr:nth-child(even) {
      background-color: white;
      // background-color: #f2f2f2;
    }

    tr:hover {
      background-color: #eee;
    }
  }
}
