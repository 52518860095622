@import 'style/vars';
@import 'style/mixins';

.link {
  display: flex;
  font-size: 12px;
  padding-left: 10px;
  cursor: pointer;
  color: $primary-color;

  &:hover {
    border-radius: 10px;
    border-width: 0px;
    color: $primary-color-dark;
  }
  &:enabled:active {
    background-color: rgb(232, 232, 232);
    border-radius: 5px;
    box-shadow: inset 1px 1px 5px rgb(219, 218, 218);
  }
}

.base {
  .optContainer {
    display: flex;
    flex-direction: row;
    border-radius: 8px;
    border: 1px solid rgb(236, 235, 235);
    background-color: white;
    padding: 10px 0;
    padding-left: 20px;
    margin: 4px 0;
    font-size: 12px;

    button {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .headerLoading {
      display: flex;
      width: 40px;
      margin-left: 10px;
      align-items: center;
      align-content: center;
    }
    .datepickerContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-family: $font-family-main;
      color: rgb(90, 90, 90);
      margin-right: 15px;

      input {
        margin-left: 46px;
        // padding: 5px 3px;
        color: rgb(90, 90, 90);
        border: 1px solid rgb(145, 145, 145);
        border-radius: 3px;

        &.error {
          border: 1px solid $font-color-error;
        }
      }
    }
    .inputContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-family: $font-family-main;
      color: rgb(90, 90, 90);
      margin-right: 15px;
      label {
        text-align: right;
      }
      input {
        min-width: 40px;
        max-width: 60px;
        margin-left: 6px;
        padding: 5px 3px;
        color: rgb(90, 90, 90);
        border: 1px solid rgb(145, 145, 145);
        border-radius: 3px;

        &.error {
          border: 1px solid $font-color-error;
        }
      }
    }
  }

  .error {
    color: $font-color-error;
    font-size: $font-size-small-2;
  }

  .tableContainer {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: 0px 0px 5px 0px #3a3a3a9f;
    background-color: white;

    .table {
      background-color: white;
      display: flex;
      flex-direction: column;
      font-family: 'Nunito Sans';
      min-width: 1200px;

      :last-child div {
        border-bottom: none;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      .header {
        display: flex;
        flex-direction: row;
        background-color: $primary-color-dark;
        color: white;
        padding: 12px 0;
        font-size: 13px;
        font-weight: bold;
        line-height: 1em;
        width: 100%;
        // border-radius: 8px 8px 0 0;
        border-bottom: 1px solid $light-grey-border-color;
        box-sizing: border-box;
      }

      .loading {
        margin: 20px;
      }

      .error {
        color: $font-color-error;
        font-size: $font-size-small-2;
        margin: 10px;
        text-align: center;
      }

      .pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        border-radius: 8px;
        margin: 10px 0;

        button {
          margin: 5px 15px;
        }
      }
    }
  }
}
