@import 'style/vars';

.base {
  .cardContainer {
    &.target {
      .title {
        color: white;
      }

      hr {
        border-color: rgb(226, 225, 225);
      }

      .avatar {
        color: #635bff;
        background-color: rgb(232, 232, 232) !important;
      }
    }
    .stack2 {
      gap: 8px;
      flex: 1 1 auto;
    }
    .titleContainer {
      width: 100%;
      height: 38px;
    }

    .title {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 500;
      font-size: 0.75rem;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      color: #667085;
    }
  }
}
