@import 'style/vars';

.base {
  display: flex;
  flex-direction: column;
  height: 1124px;
  background-image: url('/assets/images/green-landscape-1.jpg');
  background-size: auto 100%;
  background-position: right;
  color: white;

  .titleContainer {
    margin-top: 35%;
    padding: 0.6in;
    .title {
      font-size: 40px;
    }
    .subTitle {
      font-size: 16px;
    }
  }

  .footer {
    margin-top: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .logo {
      height: 80px;
      margin-left: 60px;
      margin-bottom: 15px;
    }
    .reportInfo {
      color: white;
      max-width: 300px;
      border: 1px solid white;
      border-radius: 5px;
      margin-right: 45px;
      margin-bottom: 35px;
      padding: 10px;
      font-size: 14px;
    }
  }
}
