@import 'style/vars';
@import 'style/mixins';

$row-padding: 10px 20px;
.base {
  .name {
    font-weight: bold;
    font-size: 15px;
  }
  .email {
    font-size: 14px;
    color: rgb(95, 95, 95);
  }
  .searchesLink {
    &:hover {
      color: $primary-color;
    }
  }
  .multisectorsLink {
    &:hover {
      color: $primary-color;
    }
  }
  .date {
    input {
      width: 80px !important;
    }
  }
  .pending {
    color: rgb(237, 154, 0);
    font-weight: bold;
  }
}
