@import 'style/vars';

.base {
  margin: 30px;

  .header {
    display: flex;
    margin-bottom: 30px;
    align-items: center;

    .goback {
      a {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;

        font-size: 18px;
        color: white;
        svg {
          margin-right: 5px;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .title {
      margin-left: 40px;
      font-weight: bold;
      font-size: $font-size-h2;
    }
  }
}
