@import 'style/vars';

.base {
  min-width: 700px;
  max-width: 1500px;
  background-color: white;
  color: $primary-color-dark;
  padding: 30px 60px;
  min-height: 150px;

  .error {
    color: $font-color-error;
  }

  .header {
    display: flex;
    align-items: center;
    align-content: center;

    .exportButton {
      font-size: 12px;
      margin-right: 20px;
    }

    .linkToScroll {
      font-size: 14px;
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .multisectorOldTableContainer {
    font-size: 12px;
    color: $primary-color-dark;
    margin-top: 30px;

    table {
      width: 100%;
      border-collapse: collapse;
      margin-bottom: 20px;
      margin-top: 10px;

      thead tr th {
        color: white;
        background-color: $dark-blue;
      }

      tr:nth-child(even) {
        background-color: $light-blue;
        // background-color: #f2f2f2;
      }

      tr:hover {
        background-color: #eee;
      }
    }
  }
}
