@import 'style/vars';

.base {
  background-color: white;
  display: flex;
  flex-direction: column;

  .pageBreak {
    page-break-before: always;
  }

  .report {
    width: 100%;
    background-color: white;
    // color: $primary-color-dark;
    color: #444;
    font-size: 12px;

    .title {
      color: $primary-color-dark;
    }

    .section {
      margin-top: 20px;
      margin-bottom: 50px;
      .subTitle {
        margin: 20px 0;
        font-weight: bold;
      }
    }
  }

  &.pdf .report {
    font-size: 10px;

    th,
    td {
      font-size: 10px;
    }
  }
}
