@import 'style/vars';
@import 'style/mixins';

$BLUE_COLOR: $blue; //#0689ce;
.base {
  margin: 20px;
  color: white;

  .first {
    display: inline-block;
    text-align: center;
    margin: 0 auto 30px auto;
    max-width: 900px;
    width: 100%;

    .title {
      font-size: 30px;
      line-height: 30px;
      font-weight: bold;
    }

    img {
      width: 100%;
      max-width: 400px;
      height: 200px;
      object-fit: cover;
      border-radius: 40px;
    }
  }

  .second {
    display: block;
    text-align: center;
    margin: 30px auto 30px auto;
    // width: 100%;
    max-width: 900px;

    .subtitle {
      margin-bottom: 35px;
      font-size: 25px;
      font-weight: bold;
    }
    .progressCard {
      padding: 10px;
      font-size: 18px;
      background-color: white;
      color: $BLUE_COLOR;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      text-align: center;
      border-radius: 35px;

      .cummulativeText {
        margin-top: 25px;
        font-size: 25px;
        font-weight: bold;
      }
    }
    .spinner {
      align-self: center;
      margin: 30px;
      width: 50px;
      height: 100%;
      animation-name: spin;
      animation-duration: 4000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    .buttonContainer {
      margin-top: 25px;
      button {
        width: 100%;
      }
    }
  }

  .logs {
    color: grey;
    font-size: 0.8rem;
    text-align: left;
    margin-bottom: 15px;
  }

  .error {
    margin: 20px 0;
    color: $font-color-error;

    .errorIcon {
      margin-bottom: 12px;
    }
    svg {
      width: 30px;
      height: 30px;
    }
  }
}
@media only screen and (min-width: $small-tablet-width) {
  .base {
    .second {
      .progressCard {
        padding: 30px;
        font-size: 20px;
        .cummulativeText {
          font-size: 30px;
        }
      }
    }
  }
}
@media only screen and (min-width: $desktop-width) {
  .base {
    flex-direction: row;
    .first {
      margin: 40px;
      // width: 60%;
      .title {
        font-size: 50px;
        line-height: 50px;
      }
      img {
        max-width: 100%;
        height: auto;
      }
    }
    .second {
      margin: 40px;
      // width: 40%;
      .subtitle {
        font-size: 30px;
      }
    }
  }
}
