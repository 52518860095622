@import 'style/vars';

.optionRow {
  margin-left: 10px;
  padding: 0px 15px;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    background-color: #f3f6fe;
  }

  &.isParent {
    position: relative;
    color: #000;

    &::before {
      position: absolute;
      content: '+';
      left: 1px;
      top: 4px;
      font-weight: normal;
      font-size: 1.2rem;
    }

    &.isOpen {
      &::before {
        content: '-';
      }
    }
  }

  &.optionDisabled {
    color: rgb(189, 188, 188);
    cursor: default;
  }
}

.optionRowChildren {
  .optionRow {
    margin-left: 30px;
  }
}
