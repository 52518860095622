@import 'style/vars';

.base {
  margin: 30px;

  .header {
    display: flex;
    margin-bottom: 30px;
    align-items: center;

    .goback {
      a {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        font-size: 18px;
        color: black;
        margin-right: 30px;
        svg {
          margin-right: 5px;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
