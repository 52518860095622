@font-face {
  font-family: 'Inter';
  src: url('assets/fonts/Inter/Inter-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('assets/fonts/Inter/Inter-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('assets/fonts/Inter/Inter-ExtraBold.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'NotoSansKR';
  src: url('assets/fonts/NotoSansKR/NotoSansKR-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Simsun';
  src: url('assets/fonts/simsun/simsun.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Inter', 'Nunito Sans', 'Helvetica Neue', 'NotoSansKR', 'Simsun';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input[type='radio'] {
  cursor: pointer;
}
